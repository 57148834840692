import { default as _91_46_46_46slug_93mrRS856V81Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/[...slug].vue?macro=true";
import { default as _91token_93Nvkuz83LwyMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/invite/[token].vue?macro=true";
import { default as loginBu1vWpBc1aMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_9370lHLHUUNrMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/password/reset/[token].vue?macro=true";
import { default as index3FObWTw2XWMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/password/reset/index.vue?macro=true";
import { default as _91slug_93ljoG98EiaqMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/[slug].vue?macro=true";
import { default as editutcf7h6NV8Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/[uuid]/edit.vue?macro=true";
import { default as indexw0QCYwEI0GMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/[uuid]/index.vue?macro=true";
import { default as indexFcu3CV393bMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/index.vue?macro=true";
import { default as editlycPuya3wJMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/[uuid]/edit.vue?macro=true";
import { default as indexEt9Bc79odJMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/[uuid]/index.vue?macro=true";
import { default as indexq8g6HjdZAkMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/index.vue?macro=true";
import { default as indexcuTmjxPWHRMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/budgets/index.vue?macro=true";
import { default as indexXN6Vqo6NqBMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/campaigns/index.vue?macro=true";
import { default as index6iJ8ZaUAZlMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/index.vue?macro=true";
import { default as _91uuid_93nh4cKzPPCeMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/series/[uuid].vue?macro=true";
import { default as indexM9mmLDPH6vMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/series/index.vue?macro=true";
import { default as editWA1OdcTTX3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/budgets/[budgetUuid]/edit.vue?macro=true";
import { default as create7KS5OuuNPOMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/budgets/create.vue?macro=true";
import { default as catalogtpBrnbiqiGMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/catalog.vue?macro=true";
import { default as indexabGjGtfP1mMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/index.vue?macro=true";
import { default as _91planUuid_93WolKVemjkhMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/plans/[planUuid].vue?macro=true";
import { default as createp6EaXS8vuIMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/plans/create.vue?macro=true";
import { default as indexVtuzkneuaxMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/index.vue?macro=true";
import { default as _91uuid_935UxSMaQPbcMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/campaign-evaluation/[uuid].vue?macro=true";
import { default as indexgDlOxeRAHKMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93IxEQ0yYKYMMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/[slug].vue?macro=true";
import { default as audiencesyf8L6uimqHMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/audiences.vue?macro=true";
import { default as contact56o3w9mUSKMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/contact.vue?macro=true";
import { default as index2qy04R5DhbMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/index.vue?macro=true";
import { default as _91slug_933Gh4zSIe60Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/magazines/[slug].vue?macro=true";
import { default as indexynCgHKx02zMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/magazines/index.vue?macro=true";
import { default as _91slug_93CAmc6inCOHMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/news/[slug].vue?macro=true";
import { default as indexPHV4i5RUROMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/news/index.vue?macro=true";
import { default as resourcesU1zK8qujEHMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/resources.vue?macro=true";
import { default as _91slug_93JrhfIMfI87Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/[slug].vue?macro=true";
import { default as general69jMpQuOIZMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/general.vue?macro=true";
import { default as index2SmLbbPIQ5Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/index.vue?macro=true";
import { default as lostPD1jFMaYItMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/lost.vue?macro=true";
import { default as recurringC9WL5nFxZhMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/recurring.vue?macro=true";
import { default as whitespots8p5sG7kojXMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/whitespots.vue?macro=true";
import { default as introductie1Vi9kOoxjcMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/introductie.vue?macro=true";
import { default as _91slug_93TdLJPIauzYMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/[slug].vue?macro=true";
import { default as catalogzlFOpRJ70iMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/catalog.vue?macro=true";
import { default as fix_45the_45basicsQ4zLxSN02oMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/fix-the-basics.vue?macro=true";
import { default as indexC6HEffMD4kMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/index.vue?macro=true";
import { default as _91uuid_93sEXHZkHFtbMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/[uuid].vue?macro=true";
import { default as createKwMFAb7lsOMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/create.vue?macro=true";
import { default as index8u4C5K8tNTMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/index.vue?macro=true";
import { default as edit0AgZXKuJeUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/[uuid]/edit.vue?macro=true";
import { default as indexPJ3esRVsquMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/[uuid]/index.vue?macro=true";
import { default as createYJhYneVxwuMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/create.vue?macro=true";
import { default as indexMibfKe9kJhMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/index.vue?macro=true";
import { default as presentatieBOKIwqkDRrMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/presentatie.vue?macro=true";
import { default as profilexCesEVWU5sMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/profile.vue?macro=true";
import { default as _91slug_93jQipKwZZHKMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/[slug].vue?macro=true";
import { default as editzOoyNwMaS4Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/edit.vue?macro=true";
import { default as evaluationAM9f6RePsXMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/evaluation.vue?macro=true";
import { default as finishTW29wryhMKMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/finish.vue?macro=true";
import { default as indextG9BKMB7kLMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/index.vue?macro=true";
import { default as createDrLAiQrQUHMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/create.vue?macro=true";
import { default as indexzj9MJO2HEJMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/index.vue?macro=true";
import { default as infoOp1AQAf5c3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/info.vue?macro=true";
import { default as editoEjux5SMjUMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/[uuid]/edit.vue?macro=true";
import { default as indexjC9CFaONjAMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/[uuid]/index.vue?macro=true";
import { default as createf9vS9LMZ1NMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/create.vue?macro=true";
import { default as indexZDB3d646CXMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/index.vue?macro=true";
import { default as budgetECjt8yUZZ4Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/budget.vue?macro=true";
import { default as _91uuid_93Oltu9tT69UMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/budgets/[uuid].vue?macro=true";
import { default as indexk3Gumr7PCXMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/campaigns/index.vue?macro=true";
import { default as _91slug_93umAka4lwfhMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/cases/[slug].vue?macro=true";
import { default as indexoJcvB07Nn3Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/cases/index.vue?macro=true";
import { default as index9xOoFEJkB4Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/index.vue?macro=true";
import { default as planningvtFyWOHEieMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/planning.vue?macro=true";
import { default as _91uuid_93xadBlmntXBMeta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/plans/[uuid].vue?macro=true";
import { default as _91id_932IZMlfRQn0Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/scenarios/[id].vue?macro=true";
import { default as roundtablescNSivopz31Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/roundtables.vue?macro=true";
import { default as indexNAwdUUqH44Meta } from "/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/xtra/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93mrRS856V81Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/[...slug].vue")
  },
  {
    name: "auth-invite-token",
    path: "/auth/invite/:token()",
    meta: _91token_93Nvkuz83LwyMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/invite/[token].vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginBu1vWpBc1aMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-password-reset-token",
    path: "/auth/password/reset/:token()",
    meta: _91token_9370lHLHUUNrMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/password/reset/[token].vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    meta: index3FObWTw2XWMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/auth/password/reset/index.vue")
  },
  {
    name: "buying-slug",
    path: "/buying/:slug()",
    meta: _91slug_93ljoG98EiaqMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/[slug].vue")
  },
  {
    name: "buying-bms-packages-uuid-edit",
    path: "/buying/bms-packages/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/[uuid]/edit.vue")
  },
  {
    name: "buying-bms-packages-uuid",
    path: "/buying/bms-packages/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/[uuid]/index.vue")
  },
  {
    name: "buying-bms-packages",
    path: "/buying/bms-packages",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-packages/index.vue")
  },
  {
    name: "buying-bms-positions-uuid-edit",
    path: "/buying/bms-positions/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/[uuid]/edit.vue")
  },
  {
    name: "buying-bms-positions-uuid",
    path: "/buying/bms-positions/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/[uuid]/index.vue")
  },
  {
    name: "buying-bms-positions",
    path: "/buying/bms-positions",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/bms-positions/index.vue")
  },
  {
    name: "buying-budgets",
    path: "/buying/budgets",
    meta: indexcuTmjxPWHRMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/budgets/index.vue")
  },
  {
    name: "buying-campaigns",
    path: "/buying/campaigns",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/campaigns/index.vue")
  },
  {
    name: "buying",
    path: "/buying",
    meta: index6iJ8ZaUAZlMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/index.vue")
  },
  {
    name: "buying-series-uuid",
    path: "/buying/series/:uuid()",
    meta: _91uuid_93nh4cKzPPCeMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/series/[uuid].vue")
  },
  {
    name: "buying-series",
    path: "/buying/series",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/series/index.vue")
  },
  {
    name: "buying-suppliers-uuid-budgets-budgetUuid-edit",
    path: "/buying/suppliers/:uuid()/budgets/:budgetUuid()/edit",
    meta: editWA1OdcTTX3Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/budgets/[budgetUuid]/edit.vue")
  },
  {
    name: "buying-suppliers-uuid-budgets-create",
    path: "/buying/suppliers/:uuid()/budgets/create",
    meta: create7KS5OuuNPOMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/budgets/create.vue")
  },
  {
    name: "buying-suppliers-uuid-catalog",
    path: "/buying/suppliers/:uuid()/catalog",
    meta: catalogtpBrnbiqiGMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/catalog.vue")
  },
  {
    name: "buying-suppliers-uuid",
    path: "/buying/suppliers/:uuid()",
    meta: indexabGjGtfP1mMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/index.vue")
  },
  {
    name: "buying-suppliers-uuid-plans-planUuid",
    path: "/buying/suppliers/:uuid()/plans/:planUuid()",
    meta: _91planUuid_93WolKVemjkhMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/plans/[planUuid].vue")
  },
  {
    name: "buying-suppliers-uuid-plans-create",
    path: "/buying/suppliers/:uuid()/plans/create",
    meta: createp6EaXS8vuIMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/[uuid]/plans/create.vue")
  },
  {
    name: "buying-suppliers",
    path: "/buying/suppliers",
    meta: indexVtuzkneuaxMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/buying/suppliers/index.vue")
  },
  {
    name: "campaign-evaluation-uuid",
    path: "/campaign-evaluation/:uuid()",
    meta: _91uuid_935UxSMaQPbcMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/campaign-evaluation/[uuid].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgDlOxeRAHKMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93IxEQ0yYKYMMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/[slug].vue")
  },
  {
    name: "info-audiences",
    path: "/info/audiences",
    meta: audiencesyf8L6uimqHMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/audiences.vue")
  },
  {
    name: "info-contact",
    path: "/info/contact",
    meta: contact56o3w9mUSKMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/contact.vue")
  },
  {
    name: "info",
    path: "/info",
    meta: index2qy04R5DhbMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/index.vue")
  },
  {
    name: "info-magazines-slug",
    path: "/info/magazines/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/magazines/[slug].vue")
  },
  {
    name: "info-magazines",
    path: "/info/magazines",
    meta: indexynCgHKx02zMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/magazines/index.vue")
  },
  {
    name: "info-news-slug",
    path: "/info/news/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/news/[slug].vue")
  },
  {
    name: "info-news",
    path: "/info/news",
    meta: indexPHV4i5RUROMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/news/index.vue")
  },
  {
    name: "info-resources",
    path: "/info/resources",
    meta: resourcesU1zK8qujEHMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/info/resources.vue")
  },
  {
    name: "insights-slug",
    path: "/insights/:slug()",
    meta: _91slug_93JrhfIMfI87Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/[slug].vue")
  },
  {
    name: "insights-general",
    path: "/insights/general",
    meta: general69jMpQuOIZMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/general.vue")
  },
  {
    name: "insights",
    path: "/insights",
    meta: index2SmLbbPIQ5Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/index.vue")
  },
  {
    name: "insights-lost",
    path: "/insights/lost",
    meta: lostPD1jFMaYItMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/lost.vue")
  },
  {
    name: "insights-recurring",
    path: "/insights/recurring",
    meta: recurringC9WL5nFxZhMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/recurring.vue")
  },
  {
    name: "insights-whitespots",
    path: "/insights/whitespots",
    meta: whitespots8p5sG7kojXMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/insights/whitespots.vue")
  },
  {
    name: "introductie",
    path: "/introductie",
    meta: introductie1Vi9kOoxjcMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/introductie.vue")
  },
  {
    name: "perfect-online-store-slug",
    path: "/perfect-online-store/:slug()",
    meta: _91slug_93TdLJPIauzYMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/[slug].vue")
  },
  {
    name: "perfect-online-store-catalog",
    path: "/perfect-online-store/catalog",
    meta: catalogzlFOpRJ70iMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/catalog.vue")
  },
  {
    name: "perfect-online-store-fix-the-basics",
    path: "/perfect-online-store/fix-the-basics",
    meta: fix_45the_45basicsQ4zLxSN02oMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/fix-the-basics.vue")
  },
  {
    name: "perfect-online-store",
    path: "/perfect-online-store",
    meta: indexC6HEffMD4kMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/index.vue")
  },
  {
    name: "perfect-online-store-series-uuid",
    path: "/perfect-online-store/series/:uuid()",
    meta: _91uuid_93sEXHZkHFtbMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/[uuid].vue")
  },
  {
    name: "perfect-online-store-series-create",
    path: "/perfect-online-store/series/create",
    meta: createKwMFAb7lsOMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/create.vue")
  },
  {
    name: "perfect-online-store-series",
    path: "/perfect-online-store/series",
    meta: index8u4C5K8tNTMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/series/index.vue")
  },
  {
    name: "perfect-online-store-shortcoming-uuid-edit",
    path: "/perfect-online-store/shortcoming/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/[uuid]/edit.vue")
  },
  {
    name: "perfect-online-store-shortcoming-uuid",
    path: "/perfect-online-store/shortcoming/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/[uuid]/index.vue")
  },
  {
    name: "perfect-online-store-shortcoming-create",
    path: "/perfect-online-store/shortcoming/create",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/create.vue")
  },
  {
    name: "perfect-online-store-shortcoming",
    path: "/perfect-online-store/shortcoming",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/perfect-online-store/shortcoming/index.vue")
  },
  {
    name: "presentatie",
    path: "/presentatie",
    meta: presentatieBOKIwqkDRrMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/presentatie.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilexCesEVWU5sMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/profile.vue")
  },
  {
    name: "reach-campaigns-slug",
    path: "/reach-campaigns/:slug()",
    meta: _91slug_93jQipKwZZHKMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/[slug].vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-edit",
    path: "/reach-campaigns/bms-packages/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/edit.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-evaluation",
    path: "/reach-campaigns/bms-packages/:uuid()/evaluation",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/evaluation.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid-finish",
    path: "/reach-campaigns/bms-packages/:uuid()/finish",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/finish.vue")
  },
  {
    name: "reach-campaigns-bms-packages-uuid",
    path: "/reach-campaigns/bms-packages/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/[uuid]/index.vue")
  },
  {
    name: "reach-campaigns-bms-packages-create",
    path: "/reach-campaigns/bms-packages/create",
    meta: createDrLAiQrQUHMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/create.vue")
  },
  {
    name: "reach-campaigns-bms-packages",
    path: "/reach-campaigns/bms-packages",
    meta: indexzj9MJO2HEJMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/index.vue")
  },
  {
    name: "reach-campaigns-bms-packages-info",
    path: "/reach-campaigns/bms-packages/info",
    meta: infoOp1AQAf5c3Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-packages/info.vue")
  },
  {
    name: "reach-campaigns-bms-positions-uuid-edit",
    path: "/reach-campaigns/bms-positions/:uuid()/edit",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/[uuid]/edit.vue")
  },
  {
    name: "reach-campaigns-bms-positions-uuid",
    path: "/reach-campaigns/bms-positions/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/[uuid]/index.vue")
  },
  {
    name: "reach-campaigns-bms-positions-create",
    path: "/reach-campaigns/bms-positions/create",
    meta: createf9vS9LMZ1NMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/create.vue")
  },
  {
    name: "reach-campaigns-bms-positions",
    path: "/reach-campaigns/bms-positions",
    meta: indexZDB3d646CXMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/bms-positions/index.vue")
  },
  {
    name: "reach-campaigns-budget",
    path: "/reach-campaigns/budget",
    meta: budgetECjt8yUZZ4Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/budget.vue")
  },
  {
    name: "reach-campaigns-budgets-uuid",
    path: "/reach-campaigns/budgets/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/budgets/[uuid].vue")
  },
  {
    name: "reach-campaigns-campaigns",
    path: "/reach-campaigns/campaigns",
    meta: indexk3Gumr7PCXMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/campaigns/index.vue")
  },
  {
    name: "reach-campaigns-cases-slug",
    path: "/reach-campaigns/cases/:slug()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/cases/[slug].vue")
  },
  {
    name: "reach-campaigns-cases",
    path: "/reach-campaigns/cases",
    meta: indexoJcvB07Nn3Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/cases/index.vue")
  },
  {
    name: "reach-campaigns",
    path: "/reach-campaigns",
    meta: index9xOoFEJkB4Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/index.vue")
  },
  {
    name: "reach-campaigns-planning",
    path: "/reach-campaigns/planning",
    meta: planningvtFyWOHEieMeta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/planning.vue")
  },
  {
    name: "reach-campaigns-plans-uuid",
    path: "/reach-campaigns/plans/:uuid()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/plans/[uuid].vue")
  },
  {
    name: "reach-campaigns-scenarios-id",
    path: "/reach-campaigns/scenarios/:id()",
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/reach-campaigns/scenarios/[id].vue")
  },
  {
    name: "roundtables",
    path: "/roundtables",
    meta: roundtablescNSivopz31Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/roundtables.vue")
  },
  {
    name: "xtra",
    path: "/xtra",
    meta: indexNAwdUUqH44Meta || {},
    component: () => import("/data/www/connectbybidfood.nl/bidfood-connect/releases/5/nuxt/pages/xtra/index.vue")
  }
]